.container {
  padding: var(--space-2);
  font-size: 13px;
}

.container ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  row-gap: 0.2em;
  column-gap: var(--space-2);
  align-items: center;
}

.container li {
  padding: 0;
  margin: 0;
}

.container li:not(:last-of-type):after {
  content: '|';
  margin-left: var(--space-2);
}

@media (max-width: 600px) {
  .container li:not(:last-of-type):after {
    visibility: hidden;
  }
}
