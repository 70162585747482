.container {
  padding: var(--space-3) var(--space-4);
  position: fixed;
  z-index: 1300;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0 !important;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-1);
  justify-content: center;
}

.container form {
  margin-top: var(--space-2);
}

.container label,
.container input {
  user-select: none;
}

@media (max-width: 600px) {
  .container {
    padding: var(--space-3) var(--space-1);
  }
}
